@import 'colors.scss';

#get-started,
#landing {
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-bottom: 30px;
  }

  p {
    text-align: center;
    font-weight: 600;
    font-size: 1.1em;
    margin-bottom: 2em;
  }
  p.smaller {
    font-size: 0.9em;
    font-weight: 400;
  }

  form {
    display: flex;
    justify-content: center;

    @media (max-width: 700px) {
      flex-direction: column;
      align-items: center;
    }

    input {
      border: none;
      border-radius: 4px;
      font-family: 'Inconsolata', monospace;
      min-width: 300px;
      font-size: 1em;
      padding: 0px 20px;
      margin-left: 0.5em;
      color: #222;
      border: 1px solid #ddd;
      margin-right: 1em;
      height: 43px;
      &:hover {
        border: 1px solid #aaa;
      }
      &:focus {
        border: 1px solid #aaa;
        outline: none;
      }
    }

    button {
      display: inline-block;
      font-size: 1.2rem;
      margin-left: 0em;
      margin-bottom: 1em;
      background-color: $action-color;
      height: 44px;
      width: 280px;

      @media (max-width: 700px) {
        margin-left: 0em;
        margin-top: 0.5em;
      }
    }
  }

  .cta-text {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 280px;
    text-align: center;
    padding: 10px 0;
  }

  .cta-text:nth-child(1) {
    animation: quotefade 14s 0s infinite linear;
  }

  .cta-text:nth-child(2) {
    animation: quotefade 14s 2s infinite linear;
  }

  .cta-text:nth-child(3) {
    animation: quotefade 14s 4s infinite linear;
  }

  .cta-text:nth-child(4) {
    animation: quotefade 14s 6s infinite linear;
  }

  .cta-text:nth-child(5) {
    animation: quotefade 14s 8s infinite linear;
  }

  .cta-text:nth-child(6) {
    animation: quotefade 14s 10s infinite linear;
  }
  .cta-text:nth-child(7) {
    animation: quotefade 14s 12s infinite linear;
  }

  @keyframes quotefade {
    0% {
      opacity: 0;
    }
    2% {
      opacity: 1;
    }
    12% {
      opacity: 1;
    }
    14% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  p.sub {
    font-size: 0.8em;
    text-align: center;
  }
}
